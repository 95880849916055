import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { DocumentNode, split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { errorLink } from "./errorLink";
import { authLink } from "./authLink";
import { websocketLink } from "./websocketLink";
import { httpLink, paymentsHttpLink, securitiesHttpLink } from "./httpLink";

const checkOperationDefinition = (query: DocumentNode) => {
  const definition = getMainDefinition(query);
  return definition.kind === "OperationDefinition" && definition.operation === "subscription";
};

const securitiesModuleLink = split(
  // split based on whether the query is for securities module
  ({ getContext }) => {
    return getContext()?.module === "securities";
  },
  securitiesHttpLink,
  httpLink
);

const paymentsModuleLink = split(
  // split based on whether the query is for payments module
  ({ getContext }) => {
    return getContext()?.module === "payments";
  },
  paymentsHttpLink,
  securitiesModuleLink
);

const combinedLink = split(
  // split based on operation type
  ({ query }) => {
    return checkOperationDefinition(query);
  },
  websocketLink,
  paymentsModuleLink
);

const buildApolloLinkChain = () => {
  return errorLink.concat(authLink).concat(combinedLink);
};

export const Client = new ApolloClient({
  link: buildApolloLinkChain(),
  cache: new InMemoryCache(),
  resolvers: {}
});

import React, { useCallback } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import { ExternalContentItem } from "../../../lib/ports/ExternalContentProvider";

export interface ChaptersContextualHelpProps extends WithTranslation {
  content?: ExternalContentItem;
}

const ChaptersContextualHelp = ({ content, i18n }: ChaptersContextualHelpProps) => {
  const module = "chapter-contextual-help";
  const language = i18n.language;

  const defaultContent = content?.de_content ?? "";
  let translatedContent = content?.[`${language}_content`];
  if (!translatedContent) {
    translatedContent = defaultContent;
  }

  const openHubspotChatWindow = useCallback(() => {
    if (!window["HubSpotConversations"].widget.status().loaded) {
      window.history.pushState({}, "gsSetupChat", "?gsSetupChat=true");
      window["HubSpotConversations"].widget.refresh({ openToNewThread: true });
    } else {
      window["HubSpotConversations"].widget.remove();
    }
  }, []);

  return (
    <div className={`${module}`}>
      <div
        className={`${module}__content`}
        dangerouslySetInnerHTML={{ __html: translatedContent }}
      />
      <div className={`${module}__footer`}>
        <Trans
          i18nKey="generic:company-setup.need-help-contact-us"
          components={{
            contactLink: (
              <TextLink
                variant={LinkVariant.secondary}
                to={"#"}
                onClick={openHubspotChatWindow}
                className={`${module}__footer-contact`}
              />
            )
          }}
        />
      </div>
    </div>
  );
};

export default withTranslationReady(["generic"])(ChaptersContextualHelp);

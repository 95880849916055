import { newDate } from "../dateProvider";
import { sub } from "date-fns";

export const getPreSelectedDate = (
  selectedDate: Date | string | number | undefined,
  years: number
): Date => {
  if (selectedDate) {
    return new Date(selectedDate);
  }

  return sub(newDate(), { years });
};

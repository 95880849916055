import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { StepComponentProps } from "../../../../ChaptersOrderLayout.partials";
import { LowTaxBrokerOrderData } from "lib/models/client/LowTaxBroker/LowTaxBrokerOrder";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { CountrySelect } from "sharedComponents/CountrySelect/CountrySelect";
import { RideDatePicker } from "uiLibrary/v2/components/RideDatePicker/RideDatePicker";
import { RideDropdownSelect } from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { proofOfIdentityTypeOptions } from "admin/components/OrderFilesTable/OrderFilesTable";
import { Step } from "lib/models/client/order/Step";
import * as Yup from "yup";
import { requiredString } from "lib/validation/requiredString";
import { newDate } from "common/dateProvider";

const SelectIdDocumentTypeStep = ({
  t,
  controls,
  saveData,
  order,
  currentStep
}: StepComponentProps<LowTaxBrokerOrderData> & WithTranslation) => {
  const moduleName = "select-id-document-type";

  const subChapterIndex = currentStep.chapter.index;
  let initialValues = {
    typeOfDocument:
      order.extra.shareholders?.[subChapterIndex ?? 0].proofOfIDFile?.typeOfDocument ?? "",
    issuingCountry:
      order.extra.shareholders?.[subChapterIndex ?? 0].proofOfIDFile?.issuingCountry ?? "",
    validThrough: order.extra.shareholders?.[subChapterIndex ?? 0].proofOfIDFile?.validThrough ?? ""
  };

  const onSubmit = async (values) => {
    const shareholderIndex = currentStep.chapter.index ?? 0;

    currentStep.complete();

    const idSteps = () => {
      return [
        currentStep,
        new Step({ key: "shareholder-id-file" }, currentStep.chapter),
        new Step({ key: "shareholder-id-file-back" }, currentStep.chapter),
        new Step({ key: "shareholder-id-document-review" }, currentStep.chapter),
        new Step({ key: "shareholder-proof-of-address" }, currentStep.chapter)
      ];
    };

    const passportSteps = () => {
      return [
        currentStep,
        new Step({ key: "shareholder-passport-file" }, currentStep.chapter),
        new Step({ key: "shareholder-id-document-review" }, currentStep.chapter),
        new Step({ key: "shareholder-proof-of-address" }, currentStep.chapter)
      ];
    };

    if (
      order.extra.shareholders?.[shareholderIndex]?.proofOfIDFile &&
      values["typeOfDocument"] !==
        order.extra.shareholders[shareholderIndex].proofOfIDFile?.typeOfDocument
    ) {
      order.extra.shareholders[shareholderIndex].proofOfIDFile = {};
    }

    const proofOfIDFile = order.extra.shareholders?.[shareholderIndex]?.proofOfIDFile ?? {};

    if (values["typeOfDocument"] === "PASSPORT" && proofOfIDFile.typeOfDocument !== "PASSPORT") {
      currentStep.chapter.steps = passportSteps();
    }

    if (values["typeOfDocument"] === "ID" && proofOfIDFile.typeOfDocument !== "ID") {
      currentStep.chapter.steps = idSteps();
    }

    await saveData({
      ...order.extra,
      shareholders: {
        ...order.extra.shareholders,
        [shareholderIndex]: {
          ...order.extra.shareholders?.[shareholderIndex],
          proofOfIDFile: {
            ...order.extra.shareholders?.[shareholderIndex]?.proofOfIDFile,
            typeOfDocument: values["typeOfDocument"],
            issuingCountry: values["issuingCountry"],
            validThrough: values["validThrough"]
          }
        }
      },
      ...currentStep.chapter.order.serialize()
    });
  };

  const SelectIdDocumentTypeSchema = (t) => {
    return Yup.object({
      typeOfDocument: requiredString(t),
      issuingCountry: requiredString(t),
      validThrough: Yup.string()
        .required(t("generic:validation-required"))
        .test(
          "validThrough",
          t("generic:validation-future-date"),
          (value) => new Date(value!) >= newDate()
        )
    });
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    validationSchema: SelectIdDocumentTypeSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form" data-testid={moduleName}>
        <div className="select-id-document-type subchapter-order-layout__scrollable">
          <RideDropdownSelect
            name="typeOfDocument"
            options={proofOfIdentityTypeOptions(t)}
            label={t("generic:low-tax-broker-order.file-section.document-type")}
            className={`${moduleName}__input`}
          />

          <CountrySelect
            name="issuingCountry"
            label={t("generic:low-tax-broker-order.file-section.issuing-country")}
            className={`${moduleName}__input`}
          />
          <RideDatePicker
            label={t("generic:low-tax-broker-order.file-section.valid-until")}
            name="validThrough"
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(SelectIdDocumentTypeStep);

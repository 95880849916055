import React, { PropsWithChildren } from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import CompletionDonut, { DonutCompletionStatus } from "../CompletionDonut/CompletionDonut";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { RideBadge, RideBadgeVariant } from "uiLibrary/components/RideBadge/RideBadge";

export enum TaskCardStatusEnum {
  DISABLED = "DISABLED",
  ACTION_REQUIRED = "ACTION_REQUIRED",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE"
}

export interface TaskCardProps extends PropsWithChildren<{}> {
  index: number;
  title: string;
  status: TaskCardStatusEnum;
  statusLabelWhenDisabled?: string;
}

const TaskCard = ({ index, title, status, statusLabelWhenDisabled, children }: TaskCardProps) => {
  const className = "task-card";
  const isDisabled = status === TaskCardStatusEnum.DISABLED;
  const showContent =
    status === TaskCardStatusEnum.ACTION_REQUIRED || status === TaskCardStatusEnum.IN_PROGRESS;
  return (
    <div data-testid="task-card" className={`${className}`}>
      <div className={`${className}__header-bar elevation-100`}>
        <div
          className={`${className}__header ${isDisabled ? `${className}__header--disabled` : ""}`}>
          <CompletionDonut
            data-testid={"task-card-completion-donut"}
            index={index}
            completion={getCompletion(status)}
            disabled={isDisabled}
            className={"task-card__completion-donut"}
          />
          <Typography
            category={"Paragraph"}
            size={100}
            weight={"Heavy"}
            data-testid={"task-card-title"}
            className={"task-card__title"}>
            {title}
          </Typography>
          <div className={"task-card__badge"}>
            <TaskCardStatusLabel
              data-testid={"task-card-status"}
              status={status}
              statusLabelWhenDisabled={statusLabelWhenDisabled}
            />
          </div>
        </div>
      </div>
      {showContent && <div className={"task-card__content"}>{children}</div>}
    </div>
  );
};

const getCompletion = (status: TaskCardStatusEnum) => {
  switch (status) {
    case TaskCardStatusEnum.DISABLED:
      return DonutCompletionStatus.ZERO_PERCENT;
    case TaskCardStatusEnum.ACTION_REQUIRED:
      return DonutCompletionStatus.ZERO_PERCENT;
    case TaskCardStatusEnum.IN_PROGRESS:
      return DonutCompletionStatus.SIXTY_PERCENT;
    case TaskCardStatusEnum.DONE:
      return DonutCompletionStatus.ONE_HUNDRED_PERCENT;
  }
};

interface TaskCardStatusProps {
  "data-testid"?: string;
  status: TaskCardStatusEnum;
  statusLabelWhenDisabled?: string;
}

const TaskCardStatusLabel = withTranslationReady("generic")(
  ({
    "data-testid": dataTestId,
    status,
    statusLabelWhenDisabled,
    t
  }: TaskCardStatusProps & WithTranslation) => {
    switch (status) {
      case TaskCardStatusEnum.DISABLED:
        return (
          <Typography data-testid={dataTestId} category={"Paragraph"} size={200} weight={"Light"}>
            {statusLabelWhenDisabled}
          </Typography>
        );
      case TaskCardStatusEnum.ACTION_REQUIRED:
        return (
          <RideBadge
            dataTestId={dataTestId}
            label={t("generic:action-required")}
            variant={RideBadgeVariant.OCRE}
          />
        );
      case TaskCardStatusEnum.IN_PROGRESS:
        return (
          <RideBadge
            dataTestId={dataTestId}
            label={t("generic:in-progress")}
            variant={RideBadgeVariant.PURPLE}
          />
        );
      case TaskCardStatusEnum.DONE:
        return (
          <RideBadge
            dataTestId={dataTestId}
            label={t("generic:done")}
            variant={RideBadgeVariant.GREEN}
          />
        );
    }
  }
);

export default TaskCard;

import React from "react";
import { Container } from "react-bootstrap";
import RideFooter, { AppsVariant } from "sharedComponents/RideFooter/RideFooter";
import { FeatureFlag } from "../../FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";
import TwoFactorAuthenticationNotificationContainer from "auth/components/TwoFactorAuthenticationNotification/TwoFactorAuthenticationNotificationContainer";
import ClientHeader from "client/components/ClientHeader/ClientHeader";

interface ClientLayoutProps {
  component: React.FC<any>;
}

export const ClientLayout = (props: ClientLayoutProps) => {
  const { component: Component } = props;

  return (
    <>
      <ClientHeader />
      <Container fluid className="p-0 flex-fill ride-body" data-testid="client-page-layout">
        <Component {...props} />
      </Container>
      <RideFooter app={AppsVariant.Client} />
      <FeatureFlag name={FeatureFlags.TwoFactorAuthenticationSetup}>
        <TwoFactorAuthenticationNotificationContainer />
      </FeatureFlag>
    </>
  );
};

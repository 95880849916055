import { HubspotIntegrationSectionContainer } from "admin/components/HubspotIntegrationSection/HubspotIntegrationSectionContainer";
import { ApolloQueryResult } from "apollo-client";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { KYCStatusSectionContainer } from "components/nominee/common/KYCStatus/KYCStatusSectionContainer";
import { NomineeCreateWalletContainer } from "components/nominee/common/NomineeCreateWalletContainer";
import GetUserAddress from "../GetUserAddress/GetUserAddress";
import BrokerageSurveySection from "components/nominee/NomineeCompany/components/BrokerageSurveySection";
import { MagicLinkSection } from "components/nominee/NomineeCompany/components/MagicLinkSection";
import WepaBookingsSection from "components/nominee/NomineeCompany/components/WepaBookingsSection";
import { CompanyDocumentsSectionContainer } from "components/nominee/NomineeCompany/containers/CompanyDocumentsSectionContainer";
import { NotaryServiceSectionContainer } from "components/nominee/NomineeCompany/containers/NotaryServiceSectionContainer";
import { ReferralCodeSectionContainer } from "components/nominee/NomineeCompany/containers/ReferralCodeSectionContainer";
import { CompanyType, OrderProductTypeEnum } from "global-query-types";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "lib/api/queries/graphql/NomineeCompanyDetails";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { RideButtonDeprecated } from "sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { BusinessPurposeSectionContainer } from "../companies/BusinessPurposeSection/BusinessPurposeSectionContainer";
import CompanyAssetsAndActivities from "../companies/CompanyAssetsAndActivitiesSection/CompanyAssetsAndActivities";
import { CompanyBankAccountsSectionContainer } from "../companies/CompanyBankAccountsSection/CompanyBankAccountsSectionContainer";
import { CompanyDetailsSectionContainer } from "../companies/CompanyDetailsSection/CompanyDetailsSectionContainer";
import { ProcessStatesSectionContainer } from "../companies/ProcessStatesSection/ProcessStatesSectionContainer";
import { SPVCompanyDetailsSectionContainer } from "../companies/SPVCompanyDetailsSection/SPVCompanyDetailsSectionContainer";
import { DracoonUserSectionContainer } from "../DracoonUserSection/DracoonUserSectionContainer";
import { RolesSectionContainer } from "../RolesSection/RolesSectionContainer";
import { WepaCompanyDetailsContainer } from "../WepaCompanyDetails/WepaCompanyDetailsContainer";
import { WepaServiceDetailsContainer } from "../WepaServiceDetails/WepaServiceDetailsContainer";
import PayrollServiceSectionContainer from "../PayrollServiceSection/PayrollServiceSectionContainer";
import { TaxServiceSectionContainer } from "../TaxServiceSection/TaxServiceSectionContainer";
import ServicesOrderedSection from "../ServicesOrderedSection/ServicesOrderedSection";
import AdminServiceInvoiceSectionContainer from "../AdminServiceInvoiceSection/AdminServiceInvoiceSectionContainer";
import WepaServiceInvoiceSectionContainer from "../WepaServiceInvoiceSection/WepaServiceInvoiceSectionContainer";
import { isAdminServiceUser, isWePaCompany } from "lib/types/clients/categories";
import { AlertMessage, AlertType } from "sharedComponents/AlertMessage/AlertMessage";
import { AdminServiceSectionContainer } from "../AdminServiceSection/AdminServiceSectionContainer";
import { RideLink } from "sharedComponents/RideLink/RideLink";
import ShareholderDetails from "../ShareholderDetails/ShareholderDetails";
import { StripeIntegrationSectionContainer } from "../StripeIntegrationSection/StripeIntegrationSectionContainer";

interface CompanyDetailsProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById | null;
  refetch: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

const CompanyDetails = ({ t, company, refetch }: CompanyDetailsProps) => {
  if (!company) return <></>;

  const companyCreation = company.companyCreation;
  const adminService = company.adminService;
  const companyKycEntry = company.kycEntries?.find((kycEntry) => kycEntry.type === "COMPANY");

  const entityForReferralCodeUsage = companyCreation ?? adminService;

  const gmbh = new GmbHDetails(company);

  const adminServiceOrder = gmbh.orders.find(
    (o) => o.productType === OrderProductTypeEnum.AdminService && o.extra.shareholders
  );

  const formerClientAssignmentsFilterKey =
    company.type === CompanyType.TaxService ? "advisorId" : "clientId";

  const hasCompletedWepaDirectOrder = !!company.orders.find(
    (o) => o.productType === "WepaDirect" && o.status === "Completed"
  );

  return (
    <Container>
      <CompanyDetailsSectionContainer refetchCompany={refetch} company={company!} />
      {company.type === CompanyType.Spv && <SPVCompanyDetailsSectionContainer company={company!} />}
      <BusinessPurposeSectionContainer refetchCompany={refetch} company={company} />
      <CompanyAssetsAndActivities company={company} />
      <CompanyBankAccountsSectionContainer refetchCompany={refetch} company={company} />
      <RolesSectionContainer refetchCompany={refetch} company={company} />
      {adminServiceOrder && (
        <ShareholderDetails shareholders={adminServiceOrder.extra?.shareholders} />
      )}
      <CompanyDocumentsSectionContainer entity={company} refetch={refetch} t={t} />
      <WepaBookingsSection gmbh={gmbh} />
      <WepaCompanyDetailsContainer gmbh={gmbh} />
      <WepaServiceDetailsContainer gmbh={gmbh} refetch={refetch} />
      <KYCStatusSectionContainer refetch={refetch} kycEntry={companyKycEntry} />
      {companyCreation && (
        <Row data-testid={"notaryService-section"}>
          <Col>
            <NotaryServiceSectionContainer companyCreation={companyCreation} refetch={refetch} />
          </Col>
        </Row>
      )}
      {entityForReferralCodeUsage && (
        <Row data-testid={"referralCode-section"}>
          <Col>
            <ReferralCodeSectionContainer
              company={company}
              companyCreationId={entityForReferralCodeUsage.id}
              refetch={refetch}
            />
          </Col>
        </Row>
      )}
      {companyCreation && (
        <Row data-testid={"processSteps-section"}>
          <Col>
            <ProcessStatesSectionContainer companyCreationId={companyCreation.id} />
          </Col>
        </Row>
      )}
      <Row data-testid="company-ride-wallet-section">
        <Col>
          <h2>{t("nominee-dashboard:company.company-ride-wallet")}</h2>

          <GetUserAddress wallet={company.ownedWallet} />
          <NomineeCreateWalletContainer
            companyId={company?.id}
            existingWallet={company.ownedWallet}
            refetch={refetch}
          />
        </Col>
      </Row>
      <Row data-testid="connected-accounts-section">
        <Col>
          <h2>{t("nominee-dashboard:company.connected-accounts")}</h2>
        </Col>
      </Row>
      <MagicLinkSection t={t} companyCreation={companyCreation} />
      <AdminServiceSectionContainer t={t} company={company} refetchCompany={refetch} />
      <DracoonUserSectionContainer company={company} refetchCompany={refetch} />
      <BrokerageSurveySection company={company} />
      <Row data-testid="audit-log-section">
        <Col>
          <h2>{t("nominee-dashboard:company.audit-log")}</h2>

          <RideButtonDeprecated
            href={`/admin/audit-log/Company/${company.id}`}
            openExternalUrl={true}>
            {t("nominee-dashboard:company.view-audit-log")}
          </RideButtonDeprecated>
        </Col>
      </Row>
      <HubspotIntegrationSectionContainer company={company} />
      <StripeIntegrationSectionContainer company={company} />
      <Row>
        <Col>
          <h2 id="tax-service-section">
            {t("nominee-dashboard:company.tax-advisor-section-title")}
          </h2>
          <AlertMessage type={AlertType.warning}>
            {t("nominee-dashboard:company.tax-advisor-section-warning")}
          </AlertMessage>
          {company.type === "ClientEntity" && (
            <TaxServiceSectionContainer company={company} refetch={refetch} />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 id="invoicing-section">{t("nominee-dashboard:company.invoice.title")}</h2>
          <ServicesOrderedSection company={company} />
          {isAdminServiceUser(company) && (
            <AdminServiceInvoiceSectionContainer company={company} refetch={refetch} />
          )}
          {(isWePaCompany(company) || hasCompletedWepaDirectOrder) && (
            <WepaServiceInvoiceSectionContainer company={company} refetch={refetch} />
          )}
          <PayrollServiceSectionContainer company={company} refetch={refetch} />
        </Col>
      </Row>
      <Row data-testid="more-tools-section">
        <Col>
          <h2>{t("nominee-dashboard:company.more-tools")}</h2>
          <RideLink to={`/admin/companies/${company.id}/fiscal-years`}>
            {t("nominee-dashboard:company.fiscal-years")}
          </RideLink>
          &nbsp;<b>|</b>&nbsp;
          <RideLink
            to={`/admin/former-client-assignments?${formerClientAssignmentsFilterKey}=${company.id}`}>
            {t("nominee-dashboard:company.former-client-assignments")}
          </RideLink>
          &nbsp;<b>|</b>&nbsp;
          <RideLink to={`/admin/companies/${company.id}/package-selector`}>
            {t("nominee-dashboard:company.package-selector")}
          </RideLink>
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslationReady(["generic", "nominee-dashboard", "errorCodes"])(CompanyDetails);

export const newDate = () => new Date(Date.now());

export const newDateWithoutLocalOffset = () => {
  const now = newDate();
  return new Date(now.getTime() - now.getTimezoneOffset() * 60000);
};

export const withoutLocalOffset = (date) => {
  const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  newDate.setUTCSeconds(0);
  newDate.setUTCMinutes(0);
  newDate.setUTCHours(0);

  return newDate;
};

export const toISOString = (value: string | Date | null | undefined): string | null => {
  if (!value) {
    return null;
  }

  if (value instanceof Date) {
    return value.toISOString();
  }

  return value;
};

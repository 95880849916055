import React from "react";
import { Query } from "react-apollo";
import { Redirect, Route } from "react-router";
import { FeatureFlags, UserRole } from "global-query-types";
import logger from "common/Logger";
import { ClientLayout } from "sharedComponents/templates/ClientLayout/ClientLayout";
import { isAuthenticated } from "common/Authentication/isAuthenticated";
import AdminLayout from "sharedComponents/templates/AdminLayout/AdminLayout";
import { TaxAdvisorLayout } from "sharedComponents/templates/TaxAdvisorLayout/TaxAdvisorLayout";
import { ThirdPartyTaxAdvisorLayout } from "sharedComponents/templates/ThirdPartyTaxAdvisorLayout/ThirdPartyTaxAdvisorLayout";
import { useHistory } from "react-router-dom";
import { InvitePage } from "client/components/templates/InvitePage/InvitePage";
import { ME_QUERY } from "lib/api/queries/MeQuery";
import { MeQuery } from "lib/api/queries/graphql/MeQuery";
import { FeatureFlagService } from "../../lib/services/FeatureFlagService/FeatureFlagService";

export const getDefaultPath = (userData: MeQuery) => {
  const replaceCompaniesList = FeatureFlagService.instance.isEnabled(
    FeatureFlags.ReplaceCompanyListByCompanyDropdown
  );
  switch (userData?.me?.role) {
    case UserRole.Admin:
    case UserRole.Nominee:
      return "/admin/users-companies";
    case UserRole.Client:
      return replaceCompaniesList ? "/client/home" : "/client/entities";
    case UserRole.TaxAdvisor:
      return "/tax-advisor/client-entities";
    case UserRole.ThirdPartyTaxAdvisor:
      return "/third-party-tax-advisor/clients";
    default:
      // Error with connection
      return "/login";
  }
};

export const getDefaultLayout = (userData: MeQuery, Component, props, path, fluid, history) => {
  if (userData?.me?.role === UserRole.Client) {
    return <ClientLayout component={Component} {...props} />;
  }

  if (userData?.me?.role === UserRole.Nominee) {
    return (
      <AdminLayout fluid={fluid}>
        <Component {...props} />
      </AdminLayout>
    );
  }

  if (userData?.me?.role === UserRole.TaxAdvisor) {
    return (
      <TaxAdvisorLayout>
        <Component {...props} />
      </TaxAdvisorLayout>
    );
  }

  if (userData?.me?.role === UserRole.ThirdPartyTaxAdvisor) {
    if (path.split("/")[2] === "invite-clients") {
      const returnHandler = () => {
        history.goBack();
      };

      const closeHandler = () => {
        history.push("/third-party-tax-advisor/clients");
      };
      return (
        <InvitePage
          onClose={closeHandler}
          onBack={returnHandler}
          children={<Component {...props} />}
        />
      );
    }

    return (
      <ThirdPartyTaxAdvisorLayout>
        <Component {...props} />
      </ThirdPartyTaxAdvisorLayout>
    );
  }

  return <Component {...props} />;
};

export const buildRoleBasedRoute = (
  Component,
  props,
  hasAuthorization,
  data,
  path,
  history,
  fluid = false
) => {
  const defaultPath = getDefaultPath(data);

  if (!hasAuthorization(data) || path === "/") {
    logger.debug(`Redirecting to path '${defaultPath}'`);
    return (
      <Redirect
        to={{
          pathname: defaultPath,
          state: { from: props.location }
        }}
      />
    );
  }

  logger.debug(`Navigating to ${props.location.pathname}`);
  return getDefaultLayout(data, Component, props, path, fluid, history);
};

export const PrivateRoute = ({
  component: Component,
  hasAuthorization,
  fluid = false,
  overrideLayout = false,
  ...rest
}) => {
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated()) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }

        return (
          <Query query={ME_QUERY}>
            {({ loading, data }) => {
              if (loading) return <></>;

              return overrideLayout ? (
                <Component />
              ) : (
                buildRoleBasedRoute(
                  Component,
                  props,
                  hasAuthorization,
                  data,
                  rest.path,
                  history,
                  fluid
                )
              );
            }}
          </Query>
        );
      }}
    />
  );
};

import React from "react";
import { useHistory } from "react-router";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { CompanyHeaderSection } from "client/components/CompanyHeaderSection/CompanyHeaderSection";
import { Col, Container, Row } from "react-bootstrap";
import CompanyBankCard from "sharedComponents/CompanyFields/CompanyBankCard/CompanyBankCard";
import CompanyOverviewCard from "sharedComponents/CompanyFields/CompanyOverviewCard/CompanyOverviewCard";
import CompanyNumbersAndIdsCard from "sharedComponents/CompanyFields/CompanyNumbersAndIdsCard/CompanyNumbersAndIdsCard";
import CompanyPurposeCard from "sharedComponents/CompanyFields/CompanyPurposeCard/CompanyPurposeCard";
import CompanyClientNumberCard from "sharedComponents/CompanyFields/CompanyClientNumberCard/CompanyClientNumberCard";
import CompanyRegistrationCard from "sharedComponents/CompanyFields/CompanyRegistrationCard/CompanyRegistrationCard";
import CompanyFiscalYearCard from "sharedComponents/CompanyFields/CompanyFiscalYearCard/CompanyFiscalYearCard";
import { Trans, WithTranslation } from "react-i18next";
import { Company } from "lib/types/types";
import CompanyRoleCard from "sharedComponents/CompanyFields/CompanyRoleCard/CompanyRoleCard";
import WepaBookingsCard from "sharedComponents/CompanyFields/CompanyWepaBookingsCard/WepaBookingsCard";
import { FeatureFlags, UserRole } from "global-query-types";
import { MyTaxAdvisorCompany } from "taxAdvisor/types/MyTaxAdvisorCompany";
import CompanyAccountingFrameworkCard from "sharedComponents/CompanyFields/CompanyAccountingFrameworkCard/CompanyAccountingFrameworkCard";
import DocumentSection from "client/components/DocumentSection/DocumentSection";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { CompanyTaxAdvisorCard } from "../CompanyTaxAdvisorCard/CompanyTaxAdvisorCard";
import { FiscalYearDataUploadStatusContainer } from "../FiscalYearDataUploadStatus/FiscalYearDataUploadStatusContainer";
import { AlertType, RideAlertBar } from "../../../uiLibrary/components/RideAlertBar/RideAlertBar";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import { RideUrls } from "../../../common/URLs";

interface TaxAdvisorClientOverviewSectionProps extends WithTranslation {
  company: Company | null | undefined;
  routeRef?: string;
  myTaxAdvisorCompany: MyTaxAdvisorCompany | null | undefined;
  allEditingLocked?: boolean;
  useDracoonDirectly?: boolean;
}

const TaxAdvisorClientOverviewSection = ({
  myTaxAdvisorCompany,
  company,
  routeRef = "client-entities",
  allEditingLocked = false,
  useDracoonDirectly = false,
  t
}: TaxAdvisorClientOverviewSectionProps) => {
  const history = useHistory();
  const goBackLink = `/tax-advisor/${routeRef}`;

  if (!company) return null;

  const gmbh = new GmbHDetails(company);

  const editFieldAction = (field: string) => {
    return () => history.push(`/tax-advisor/client-entities/${gmbh.id}/${field}`);
  };

  const handleEditField = allEditingLocked ? undefined : editFieldAction;

  return (
    <>
      <CompanyHeaderSection
        gmbh={gmbh}
        goBackLink={goBackLink}
        showClientTags={true}
        showTabbedNavigation={false}
      />
      <Container className="my-5">
        <Row>
          <Col>
            <CompanyTaxAdvisorCard t={t} gmbh={gmbh} />
            <CompanyClientNumberCard
              t={t}
              gmbh={gmbh}
              field="client-number"
              handleEditField={handleEditField}
              asUser={UserRole.TaxAdvisor}
            />
            <WepaBookingsCard
              gmbh={gmbh}
              myTaxAdvisorCompany={myTaxAdvisorCompany}
              asUser={UserRole.TaxAdvisor}
            />
            <FeatureFlag name={FeatureFlags.FiscalYearDataUploadConfirmation}>
              {gmbh.fiscalYear && <FiscalYearDataUploadStatusContainer gmbh={gmbh} />}
            </FeatureFlag>
            <CompanyFiscalYearCard
              t={t}
              gmbh={gmbh}
              handleEditField={handleEditField}
              asUser={UserRole.TaxAdvisor}
            />
            <CompanyAccountingFrameworkCard t={t} gmbh={gmbh} handleEditField={handleEditField} />
            <CompanyOverviewCard t={t} gmbh={gmbh} />
            <CompanyRegistrationCard t={t} gmbh={gmbh} />
            <CompanyNumbersAndIdsCard t={t} gmbh={gmbh} handleEditField={handleEditField} />
            <CompanyPurposeCard t={t} gmbh={gmbh} />
            <CompanyBankCard t={t} gmbh={gmbh} />
            <CompanyRoleCard gmbh={gmbh} />

            <FeatureFlag name={FeatureFlags.PTAWepaDocuments}>
              {useDracoonDirectly ? (
                <RideAlertBar
                  type={AlertType.INFO}
                  message={
                    <Trans
                      i18nKey="generic:dracoon-only-alert.message"
                      components={{
                        dracoonLink: (
                          <TextLink
                            to={RideUrls.Dracoon}
                            variant={LinkVariant.info}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        )
                      }}
                    />
                  }
                />
              ) : (
                <DocumentSection
                  testid={"wepa"}
                  nodeList={gmbh.wepaNodeList}
                  gmbh={gmbh}
                  data-testid="wepa-document-section"
                />
              )}
            </FeatureFlag>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withTranslationReady(["client", "tax-advisor", "generic"])(
  TaxAdvisorClientOverviewSection
);

import React from "react";
import { Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { Form, FormikProvider, useFormik } from "formik";
import OnboardingStepCard, {
  EditableOnboardingStepCardProps,
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import { getCurrentYear } from "lib/dateTime/getCurrentYear";
import { doNothing } from "../../../../../common/stubs";
import { FiscalYearCard } from "../../../UnifiedShortOrderForm/ProductSelectionStep/AdminServiceAdditionalYears/FiscalYearCard/FiscalYearCard";

const moduleName = "wepa-select-additional-fiscal-years";

export interface WepaSelectAdditionalFiscalYearsProps extends EditableOnboardingStepCardProps {
  stepNumber: number;
  status: OnboardingStepCardStatus;
  selectFiscalYears: (numberOfAdditionalYears: number) => Promise<void>;
  fiscalYearStartingDate?: string;
  numberOfFiscalYearsPreSelected?: number;
}

const WepaSelectAdditionalFiscalYears = ({
  t,
  stepNumber,
  status,
  selectFiscalYears,
  numberOfFiscalYearsPreSelected,
  fiscalYearStartingDate
}: WepaSelectAdditionalFiscalYearsProps & WithTranslation) => {
  const fiscalYearInputName = `startingFiscalYear`;
  const currentYear = getCurrentYear();
  const previousFiscalYears = [currentYear - 1, currentYear - 2];

  const formik = useFormik({
    initialValues: {
      startingFiscalYear: getCurrentYear() - (numberOfFiscalYearsPreSelected ?? 0)
    },
    enableReinitialize: true,
    onSubmit: doNothing
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <OnboardingStepCard
          data-testid={`${moduleName}`}
          number={stepNumber}
          status={status}
          title={t("generic:select-additional-fiscal-years.title")}>
          <div className={`${moduleName}__content`}>
            <div className={`${moduleName}__text`}>
              <Typography
                data-testid={`${moduleName}-question`}
                category={"Paragraph"}
                size={100}
                weight={"Heavy"}>
                <Trans
                  i18nKey={"generic:select-additional-fiscal-years.wepa.question"}
                  values={{ currentYear }}
                />
              </Typography>
              <Typography
                data-testid={`${moduleName}-question-explanation`}
                category={"Paragraph"}
                size={100}
                weight={"Light"}>
                {t("generic:select-additional-fiscal-years.wepa.question-explanation")}
              </Typography>
            </div>
            <Typography
              data-testid={`${moduleName}-pricing`}
              category={"Paragraph"}
              size={100}
              weight={"Heavy"}
              className={`${moduleName}__initial-price-button`}>
              <Trans i18nKey={"generic:select-additional-fiscal-years.wepa.pricing-information"} />
            </Typography>
            <div className={`${moduleName}__fiscal-years`}>
              {previousFiscalYears.map((year) => (
                <FiscalYearCard
                  key={year}
                  year={year}
                  inputName={fiscalYearInputName}
                  classNames={`${moduleName}__fiscal-year-card`}
                  fiscalYearStartingDate={fiscalYearStartingDate}
                />
              ))}
            </div>
          </div>
          <div className={`${moduleName}__actions`}>
            <RideButton
              type={"button"}
              onClick={async () => {
                await selectFiscalYears(0);
                await formik.setValues({ [fiscalYearInputName]: currentYear });
              }}
              data-testid={"no-additional-years"}
              label={t("generic:select-additional-fiscal-years.no-additional-years")}
              variant={RideButtonVariant.GHOST}
              size={RideButtonSize.BIG}
            />
            <RideButton
              type={"submit"}
              data-testid={"select-and-continue"}
              label={t("generic:select-additional-fiscal-years.select-and-continue")}
              variant={RideButtonVariant.PRIMARY}
              size={RideButtonSize.BIG}
              onClick={async () => {
                const selectedStartingYear = formik.values[fiscalYearInputName];
                await selectFiscalYears(previousFiscalYears.indexOf(selectedStartingYear) + 1);
              }}
            />
          </div>
        </OnboardingStepCard>
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(WepaSelectAdditionalFiscalYears);

import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";
import { useHistory } from "react-router-dom";
import { GrundungServiceStatusEnum } from "../../../lib/models/client/GmbH";
import { RideBadge, RideBadgeVariant } from "../../../uiLibrary/components/RideBadge/RideBadge";
import { AddIcon } from "../../../sharedComponents/icons/add";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";
import {
  FeatureFlags,
  OrderCompanyStatusEnum,
  OrderProductTypeEnum
} from "../../../global-query-types";
import { MyGmbHsQuery_companies_orders } from "components/client/MyGmbHs/graphql/MyGmbHsQuery";
import CompletionDonut, { DonutCompletionStatus } from "../CompletionDonut/CompletionDonut";

const moduleName = "company-dropdown";

export interface CompanyDropdownItemData {
  id: string;
  name: string;
  status: GrundungServiceStatusEnum;
  selected?: boolean | null;
  orders?: Omit<MyGmbHsQuery_companies_orders, "__typename">[];
}

interface CompanyDropdownProps extends WithTranslation {
  companies: CompanyDropdownItemData[];
  openTheAddCompanyModal: () => void;
}

const CompanyDropdown = ({ t, companies, openTheAddCompanyModal }: CompanyDropdownProps) => {
  const history = useHistory();
  const sendToOldAddCompanyPage = () => {
    history.push("/client/my-gmbhs/add");
  };
  const newAddGmbHModal = FeatureFlagService.instance.isEnabled(FeatureFlags.NewAddGmbHModal);
  return (
    <div data-testid="company-dropdown" className={moduleName}>
      {companies.map((company) => (
        <CompanyDropdownItem key={company.id} company={company} />
      ))}
      <div
        className={`${moduleName}__add-company-button`}
        onClick={() => {
          if (newAddGmbHModal) {
            openTheAddCompanyModal();
          } else {
            sendToOldAddCompanyPage();
          }
        }}
        data-testid={"add-company-button"}>
        <AddIcon />
        {t("generic:add-company")}
      </div>
    </div>
  );
};

const CompanyDropdownItem = withTranslationReady(["generic"])(
  ({ company, t }: { company: CompanyDropdownItemData } & WithTranslation) => {
    const history = useHistory();
    const addExistingCompanyOrder = getAddExistingCompanyOrder(company);
    const isGSCompany =
      !addExistingCompanyOrder && company.status !== GrundungServiceStatusEnum.NOT_ACQUIRED;

    const shouldShowBadgeForGSCompany =
      isGSCompany && company.status !== GrundungServiceStatusEnum.FOUNDED;

    const shouldShowBadgeForNonGSCompany =
      !isGSCompany && addExistingCompanyOrder?.status === OrderCompanyStatusEnum.InProgress;

    const showBadge = shouldShowBadgeForGSCompany || shouldShowBadgeForNonGSCompany;

    return (
      <div
        className={`${moduleName}__item ${
          company.selected ? "company-dropdown__item--active" : ""
        }`}
        data-testid={"company-dropdown-item"}
        onClick={() => {
          history.push(`/client/entities/${company.id}/general-info`);
        }}>
        <CompletionDonut
          data-testid={"company-dropdown-item-completion"}
          completion={getCompletionStatus(company)}
        />
        <div
          data-testid={"company-dropdown-item-badge"}
          className={`${moduleName}__company-details`}>
          <span className={`${moduleName}__company-details__company-name`}>{company.name}</span>
          {showBadge && (
            <RideBadge
              variant={getGSBadgeColorByStatus(company.status)}
              label={
                company.status === GrundungServiceStatusEnum.IN_FOUNDATION
                  ? t("generic:gmbh-badge-status.grundung-service.in-foundation")
                  : t("generic:gmbh-badge-status.grundung-service.draft")
              }
            />
          )}
        </div>
      </div>
    );

    function getGSBadgeColorByStatus(status: GrundungServiceStatusEnum): string {
      return status === GrundungServiceStatusEnum.IN_FOUNDATION
        ? RideBadgeVariant.PURPLE
        : RideBadgeVariant.OCRE;
    }
  }
);

function getAddExistingCompanyOrder(company: CompanyDropdownItemData) {
  return company.orders?.find(
    (order) => order.productType === OrderProductTypeEnum.AddExistingCompany
  );
}

function getCompletionStatus(company: CompanyDropdownItemData): DonutCompletionStatus {
  const gsStatusToDonutCompletionStatusMap = {
    [GrundungServiceStatusEnum.NOT_ACQUIRED]: DonutCompletionStatus.ONE_HUNDRED_PERCENT,
    [GrundungServiceStatusEnum.ORDER_PENDING]: DonutCompletionStatus.SIXTEEN_PERCENT,
    [GrundungServiceStatusEnum.IN_FOUNDATION]: DonutCompletionStatus.SIXTY_PERCENT,
    [GrundungServiceStatusEnum.FOUNDED]: DonutCompletionStatus.ONE_HUNDRED_PERCENT
  };

  if (company.status === GrundungServiceStatusEnum.NOT_ACQUIRED && company.orders) {
    const addExistingCompanyOrder = getAddExistingCompanyOrder(company);

    if (addExistingCompanyOrder?.status === OrderCompanyStatusEnum.InProgress) {
      return DonutCompletionStatus.SIXTEEN_PERCENT;
    }
  }

  return gsStatusToDonutCompletionStatusMap[company.status];
}

export default withTranslationReady(["generic"])(CompanyDropdown);

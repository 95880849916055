import React from "react";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import { TFunction } from "i18next";
import { Button, Col, Row } from "react-bootstrap";
import { CompanyType } from "../../../global-query-types";
import { ApolloQueryResult } from "apollo-client";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById,
  NomineeCompanyDetails_companyById_externalStorageUsers
} from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import { ClientSelect } from "../../../client/components/ClientSelect/ClientSelect";
import { personNameWithFallback } from "lib/formatters/personFormatter";
import { filterNotNull } from "../../../lib/types/filterNotNull";
import _ from "lodash";
import RideCardTable from "../../../sharedComponents/RideCardTable/RideCardTable";
import { RideTableHeader } from "../../../sharedComponents/RideTableHeader/RideTableHeader";
import { ClientInput } from "../../../client/components/ClientInput/ClientInput";
import { RideTableRow } from "../../../sharedComponents/RideTableRow/RideTableRow";
import RideTableCell, {
  RideTableCellVariant
} from "../../../sharedComponents/RideTableCell/RideTableCell";
import { formatValue } from "../../../common/formatters";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import { Person } from "lib/types/types";
import { generatePassword } from "../../../common/generatePassword";
import ConfirmationModal from "../../../sharedComponents/ConfirmationModal/ConfirmationModal";
import logger from "../../../common/Logger";
import * as Yup from "yup";
import { EmailValidation } from "../../../lib/validation/EmailSchema";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "../../../common/i18n/withTranslationReady";

interface DracoonUserSectionProps extends WithTranslation {
  company: NomineeCompanyDetails_companyById;
  createExternalStorageUser: (
    email: string,
    password: string,
    personId?: string | null
  ) => Promise<void>;
  deleteExternalStorageUser: (id: string) => Promise<void>;
  refreshExternalStorageUserPermissions: (id: string) => Promise<void>;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

const DracoonUserSection = ({
  t,
  company,
  createExternalStorageUser,
  deleteExternalStorageUser,
  refreshExternalStorageUserPermissions,
  refetchCompany
}: DracoonUserSectionProps) => {
  if (company.type !== CompanyType.TaxService) return null;

  return (
    <>
      <Row>
        <Col>
          <h2>{t("nominee-dashboard:company.dracoon-user")}</h2>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ExternalStorageUserTable
            company={company}
            createExternalStorageUser={createExternalStorageUser}
            deleteExternalStorageUser={deleteExternalStorageUser}
            refreshExternalStorageUserPermissions={refreshExternalStorageUserPermissions}
            refetchCompany={refetchCompany}
          />
        </Col>
      </Row>
    </>
  );
};

export default withTranslationReady(["nominee-dashboard", "generic"])(DracoonUserSection);

const ExternalStorageUserTable = withTranslationReady(["nominee-dashboard", "generic"])(
  ({
    t,
    company,
    createExternalStorageUser,
    deleteExternalStorageUser,
    refreshExternalStorageUserPermissions,
    refetchCompany
  }: DracoonUserSectionProps) => {
    const handleCreateUser = async (values) => {
      await createExternalStorageUser(values.dracoonEmail, values.dracoonPass, values.person);
      await refetchCompany();
    };

    const handleDeleteUser = async (id: string) => {
      await deleteExternalStorageUser(id);
      await refetchCompany();
    };

    const handleRefreshPermissions = async (id: string) => {
      refreshExternalStorageUserPermissions(id).catch((e) => logger.error(e));
      setTimeout(() => refetchCompany().catch((e) => logger.error(e)), 2000);
    };

    const personMemberships = company.group.memberships?.map((m) => m.person) ?? [];
    const persons = _.uniqBy(filterNotNull(personMemberships), "id");

    const columns = [
      {
        label: t("nominee-dashboard:tax-service.person"),
        name: "person"
      },
      {
        label: t("generic:email"),
        name: "email"
      },
      {
        label: t("nominee-dashboard:tax-service.storage-users.id"),
        name: "id"
      },
      {
        label: t("generic:actions"),
        name: "actions"
      },
      {
        label: t("generic:status"),
        name: "status"
      }
    ];

    return (
      <RideCardTable title={t("nominee-dashboard:tax-service.storage-users.header")}>
        <RideTableHeader columns={columns} />

        {company.externalStorageUsers?.map((user) => (
          <ExistingUserRow
            t={t}
            user={user}
            handleDeleteUser={handleDeleteUser}
            handleRefreshPermissions={handleRefreshPermissions}
            key={user.id}
          />
        ))}

        <AddNewUserRow t={t} persons={persons} onSubmit={handleCreateUser} />
      </RideCardTable>
    );
  }
);

type ExistingUserRowProps = {
  t: TFunction;
  user: NomineeCompanyDetails_companyById_externalStorageUsers;
  handleDeleteUser: (id: string) => Promise<void>;
  handleRefreshPermissions: (id: string) => Promise<void>;
};

const ExistingUserRow = ({
  t,
  user,
  handleDeleteUser,
  handleRefreshPermissions
}: ExistingUserRowProps) => {
  return (
    <RideTableRow colCount={5}>
      <RideTableCell
        variant={RideTableCellVariant.text}
        value={formatValue(personNameWithFallback(user.person))}
        dataTestId="person"
      />
      <RideTableCell variant={RideTableCellVariant.text} value={user.email} dataTestId="email" />
      <RideTableCell variant={RideTableCellVariant.text} value={user.externalId} dataTestId="id" />
      <RideTableCell
        variant={RideTableCellVariant.text}
        value={
          <>
            <DeleteUserButton t={t} user={user} handleDeleteUser={handleDeleteUser} />
            <RefreshPermissionsButton
              t={t}
              user={user}
              handleRefreshPermissions={handleRefreshPermissions}
            />
          </>
        }
      />
      <RideTableCell
        variant={RideTableCellVariant.secondaryText}
        value={formatValue(user.status)}
        dataTestId="status"
      />
    </RideTableRow>
  );
};

const DeleteUserButton = ({
  t,
  handleDeleteUser,
  user
}: Pick<ExistingUserRowProps, "t" | "handleDeleteUser" | "user">) => {
  return (
    <ConfirmationModal
      onConfirm={async () => {
        await handleDeleteUser(user.id).catch((e) => logger.error(e));
      }}
      confirmLabel={t("generic:delete")}
      title={t("nominee-dashboard:tax-service.storage-users.delete-confirmation-title")}
      dialogBody={t("nominee-dashboard:tax-service.storage-users.delete-confirmation-text")}>
      {(setVisibility) => (
        <RideButtonDeprecated
          data-testid="delete-action"
          variant={ButtonVariant.Danger}
          size={ButtonSize.Small}
          onClick={() => setVisibility(true)}>
          {t("generic:delete")}
        </RideButtonDeprecated>
      )}
    </ConfirmationModal>
  );
};

const RefreshPermissionsButton = ({
  t,
  handleRefreshPermissions,
  user
}: Pick<ExistingUserRowProps, "t" | "user" | "handleRefreshPermissions">) => {
  return (
    <ConfirmationModal
      onConfirm={async () => {
        handleRefreshPermissions(user.id).catch((e) => logger.error(e));
      }}
      confirmLabel={t("nominee-dashboard:tax-service.storage-users.refresh-permissions")}
      title={t("nominee-dashboard:tax-service.storage-users.refresh-confirmation-title")}
      dialogBody={t("nominee-dashboard:tax-service.storage-users.refresh-confirmation-text")}>
      {(setVisibility) => (
        <RideButtonDeprecated
          data-testid="refresh-action"
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          onClick={() => setVisibility(true)}>
          {t("nominee-dashboard:tax-service.storage-users.refresh-permissions")}
        </RideButtonDeprecated>
      )}
    </ConfirmationModal>
  );
};

const DracoonEmailSchema = Yup.object().shape({
  dracoonEmail: EmailValidation
});

const AddNewUserRow = ({
  t,
  persons,
  onSubmit
}: {
  t: TFunction;
  persons: Person[];
  onSubmit: FormikConfig<any>["onSubmit"];
}) => {
  const initialPerson = persons[0];
  const generatedPassword = generatePassword();

  const formik = useFormik({
    initialValues: {
      dracoonEmail: initialPerson?.user?.email,
      dracoonPass: generatedPassword,
      person: persons[0]?.id
    },
    validationSchema: DracoonEmailSchema,
    onSubmit: onSubmit
  });

  const personOptions = persons.map((person) => ({
    label: personNameWithFallback(person),
    value: person.id
  }));

  const handlePersonChange = (personId: string) => {
    const person = persons.find((p) => p.id === personId);
    formik.setFieldValue("dracoonEmail", person?.user?.email);
    formik.setFieldValue("person", personId);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <RideTableRow colCount={4}>
          <RideTableCell
            variant={RideTableCellVariant.text}
            value={
              <ClientSelect
                label={t("nominee-dashboard:tax-service.person")}
                name="person"
                options={personOptions}
                changeHandler={handlePersonChange}
              />
            }
          />
          <RideTableCell
            variant={RideTableCellVariant.text}
            value={<ClientInput name="dracoonEmail" type="email" label={t("generic:email")} />}
          />
          <RideTableCell
            variant={RideTableCellVariant.text}
            value={
              <ClientInput name="dracoonPass" type="text" disabled label={t("generic:password")} />
            }
          />
          <RideTableCell
            variant={RideTableCellVariant.text}
            value={
              <Button
                variant="primary"
                className="float-right"
                data-testid={`submit-button`}
                type="submit">
                {t("generic:add")}
              </Button>
            }
          />
        </RideTableRow>
      </Form>
    </FormikProvider>
  );
};

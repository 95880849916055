import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { MyGmbHsQuery, MyGmbHsQueryVariables } from "./graphql/MyGmbHsQuery";
import { CompanyType } from "../../../global-query-types";

export const MY_GMBHS_QUERY = gql`
  query MyGmbHsQuery($type: CompanyType) {
    companies(type: $type) {
      id
      name
      legalForm
      companyCreation {
        id
        flowType
        currentStep
        processStates
      }
      adminService {
        id
        flowType
        currentStep
        isArchived
        orderedAt
      }
      myTaxService {
        id
        type
        taxAdvisorStatus
      }
      bankAccounts {
        id
        name
        bankProvider
        iban
        plan
      }
      brokerageAccounts {
        id
        accountNumber
        brokerBank
        brokerName
        tokenUpdatedAt
        token
        queryId
        validity
        errorCodes
        isTokenExist
      }
      group {
        memberships {
          company {
            id
          }
          person {
            id
          }
          role
        }
      }
      finApiAccount {
        id
        finApiBankConnections {
          id
        }
      }
      orderStatus
      companyFoundationOrderId
      leiOrderSource
      orders {
        id
        productType
        extra
        status
        submissionId
        deletedAt
      }
    }
  }
`;

export const GetMyGmbHs = () =>
  useQuery<MyGmbHsQuery, MyGmbHsQueryVariables>(MY_GMBHS_QUERY, {
    variables: { type: CompanyType.ClientEntity },
    fetchPolicy: "network-only"
  });

import { AdminServiceStatusEnum, GmbH } from "./GmbH";
import { BrokerageAccount, Company } from "lib/types/types";
import { sum } from "lodash";
import moment from "moment";
import { newDate } from "common/dateProvider";
import { RolesInGroup } from "../../../global-query-types";

export interface DocumentProps {
  name: string;
  id: string;
}

export class GmbHDetails extends GmbH {
  constructor(private details: Company, readonly _refetch?: () => Promise<void>) {
    super(details, _refetch);
  }

  get companyObject() {
    return this.details;
  }

  get taxService() {
    return this.details?.myTaxService;
  }

  get taxAdvisor() {
    const advisor = this.details?.myTaxService?.advisor;

    return advisor
      ? new GmbH({
          id: advisor.id,
          name: advisor.name,
          legalForm: advisor.legalForm,
          companyCreation: null,
          adminService: null,
          myTaxService: null
        } as Company)
      : undefined;
  }

  get companyFoundationOrderId() {
    return this.details.companyFoundationOrderId;
  }

  get members() {
    return this.details.group?.memberships;
  }

  get asUpgradeRequiredFrom() {
    return this.details.asUpgradeRequiredFrom;
  }

  get hasLeiSection() {
    return !!this.details.leiOrderSource;
  }

  get customerSignedAdminServiceContract() {
    return this.details.customerSignedAdminServiceContract;
  }

  get directors() {
    return this.getMembersByRole(RolesInGroup.Director);
  }

  get shareholders() {
    return this.getMembersByRole(RolesInGroup.Shareholder);
  }

  get silentPartners() {
    return this.getMembersByRole(RolesInGroup.SilentPartner);
  }

  get silentPartnerShares(): number {
    return sum(this.silentPartners.map((s) => s.share?.share ?? s.share ?? 0));
  }

  get hasAddress() {
    return (
      this.details.businessAddress?.street ||
      this.details.businessAddress?.postalCode ||
      this.details.businessAddress?.city
    );
  }

  private get hasAFoundationServiceOngoingOrder() {
    return this.companyCreationId && !this.hasSubmittedGSOrder;
  }

  get url() {
    const companyDetailsPageUrl = `/client/entities/${this.details.id}/general-info`;
    const onGoingOrderFormUrl = `/booking/gmbh/${this.details.companyCreation?.id}`;

    if (this.hasAFoundationServiceOngoingOrder) {
      return onGoingOrderFormUrl;
    }

    return companyDetailsPageUrl;
  }

  get adminServiceUrl() {
    return `/client/entities/${this.details.id}/admin-service`;
  }

  get street() {
    return this.details.businessAddress?.street;
  }

  get streetLine2() {
    return this.details.businessAddress?.streetLine2;
  }

  get postalCode() {
    return `D-${this.details.businessAddress?.postalCode}`;
  }

  get city() {
    return this.details.businessAddress?.city;
  }

  get notarizationDate() {
    return this.details.notarizationDate;
  }

  get registerCourt() {
    return this.details.registerCourt;
  }

  get registration() {
    return this.details.registration;
  }

  get applicationForEntryDate() {
    return this.details.applicationForEntryDate;
  }

  get commercialRegisterEntryDate() {
    return this.details.commercialRegisterEntryDate;
  }

  get taxNumber() {
    return this.details.taxNumber;
  }

  get vatNumber() {
    return this.details.vatNumber;
  }

  get clientNumber() {
    return this.details.clientNumber;
  }

  get thirdPartyTaxAdvisorClientNumber() {
    return this.details.thirdPartyTaxAdvisorClientNumber;
  }

  get fiscalYear() {
    return this.details.fiscalYear;
  }

  get locked() {
    return this.details.locked ?? false;
  }

  get lei() {
    return this.details.lei;
  }

  get purpose() {
    return this.details.businessPurpose;
  }

  get bankAccounts() {
    return this.details.bankAccounts;
  }

  get storage() {
    return JSON.parse(this.details.externalStorageNodes ?? "{}");
  }

  get hasPrimaryDataStorage(): boolean {
    return this.hasAnyNodes(this.primaryDataNodeList);
  }

  get hasAdminServiceStorage(): boolean {
    return this.hasAnyNodes(this.adminServiceNodeList);
  }

  get hasWepaStorage(): boolean {
    return this.hasAnyNodes(this.wepaNodeList);
  }

  get primaryDataNodeList(): DocumentProps[] {
    return [{ name: "master-data", id: this.storage.masterData }];
  }

  get adminServiceNodeList(): DocumentProps[] {
    return [
      { name: "contracts", id: this.storage.contracts },
      { name: "accounting", id: this.storage.accounting },
      { name: "taxes", id: this.storage.taxes },
      { name: "evaluationAndReports", id: this.storage.evaluationAndReports }
    ];
  }

  get wepaNodeList(): DocumentProps[] {
    return [{ name: "wepa", id: this.storage.wepa }];
  }

  get brokerageAccounts(): BrokerageAccount[] | null {
    return this.details.brokerageAccounts ?? null;
  }

  get displayBrokerageSurvey(): boolean {
    if (!this.companyCreationId && !this.hasAdminService && !this.isWepaClient) return false;
    if (this.hasAdminService && this.adminServiceStatus !== AdminServiceStatusEnum.ACTIVE)
      return false;
    if (this.isInFoundation) return false;
    if (this.hasAFoundationServiceOngoingOrder) return false;
    if (this.brokerageAccounts?.length) return false;

    const reminderAnswers = ["doItLater", "stillNeedToGetOne"];

    const lastAnsweredAt = this.details.brokerageSurvey?.updatedAt;
    if (!lastAnsweredAt) return true;

    const monthsPassed = moment(newDate()).diff(moment(lastAnsweredAt), "months");
    const currentAnswer = this.details.brokerageSurvey?.answer ?? "";
    const isCurrentAnswerRemindable = reminderAnswers.includes(currentAnswer);
    return isCurrentAnswerRemindable && monthsPassed > 2;
  }

  get wepaId() {
    return this.details.wepaId;
  }

  get wepaService() {
    return this.details.wepaService;
  }

  private getMembersByRole(roleFilter: RolesInGroup) {
    return this.details.group?.memberships?.filter(({ role }) => role === roleFilter) ?? [];
  }

  private hasAnyNodes(nodes: DocumentProps[]) {
    return nodes.some((node) => !!node.id);
  }

  get orders() {
    return this.details.orders ?? [];
  }

  get migrations() {
    return this.details.migrations ?? [];
  }
}

import React from "react";
import { ApolloQueryResult } from "apollo-client";
import DracoonUserSection from "./DracoonUserSection";
import { useMutation } from "react-apollo";
import { NOMINEE_CREATE_EXTERNAL_STORAGE_USER } from "../../../components/nominee/NomineeCompany/Mutations";
import logger from "../../../common/Logger";
import RequestFeedbackHandler from "../../../components/generic/RequestFeedbackHandler";
import {
  NomineeCompanyDetails,
  NomineeCompanyDetails_companyById
} from "../../../lib/api/queries/graphql/NomineeCompanyDetails";
import {
  NomineeCreateExternalStorageUser,
  NomineeCreateExternalStorageUserVariables
} from "../../../components/nominee/NomineeCompany/graphql/NomineeCreateExternalStorageUser";
import {
  DeleteExternalStorageUser,
  DeleteExternalStorageUserVariables
} from "../../../lib/api/mutations/graphql/DeleteExternalStorageUser";
import { DELETE_EXTERNAL_STORAGE_USER } from "../../../lib/api/mutations/DELETE_EXTERNAL_STORAGE_USER";
import {
  RefreshExternalStorageUserPermissions,
  RefreshExternalStorageUserPermissionsVariables
} from "../../../lib/api/mutations/graphql/RefreshExternalStorageUserPermissions";
import { REFRESH_EXTERNAL_STORAGE_USER_PERMISSIONS } from "../../../lib/api/mutations/REFRESH_EXTERNAL_STORAGE_USER_PERMISSIONS";

interface DracoonUserSectionContainerProps {
  company: NomineeCompanyDetails_companyById;
  refetchCompany: () => Promise<ApolloQueryResult<NomineeCompanyDetails>>;
}

export const DracoonUserSectionContainer = ({
  company,
  refetchCompany
}: DracoonUserSectionContainerProps) => {
  const [createCreateExternalStorageUserMutation, createExternalStorageUserRequest] = useMutation<
    NomineeCreateExternalStorageUser,
    NomineeCreateExternalStorageUserVariables
  >(NOMINEE_CREATE_EXTERNAL_STORAGE_USER);

  const [deleteExternalStorageUserMutation, deleteExternalStorageUserRequest] = useMutation<
    DeleteExternalStorageUser,
    DeleteExternalStorageUserVariables
  >(DELETE_EXTERNAL_STORAGE_USER);

  const [refreshExternalStorageUserPermissionsMutation] = useMutation<
    RefreshExternalStorageUserPermissions,
    RefreshExternalStorageUserPermissionsVariables
  >(REFRESH_EXTERNAL_STORAGE_USER_PERMISSIONS);

  const taxAdvisorCompanyId = company?.id;

  const createExternalStorageUser = async (
    email: string,
    password: string,
    personId?: string | null
  ) => {
    try {
      await createCreateExternalStorageUserMutation({
        variables: { email, password, taxAdvisorCompanyId, personId: personId ?? "" }
      });
    } catch (error) {
      logger.errorMessage(`Unable to create external storage user for tax advisor`);
      logger.error(error);
    }
  };

  const deleteExternalStorageUser = async (id: string) => {
    try {
      await deleteExternalStorageUserMutation({
        variables: { id }
      });
    } catch (error) {
      logger.errorMessage(`Unable to delete external storage user for tax advisor`);
      logger.error(error);
    }
  };

  const refreshExternalStorageUserPermissions = async (id: string) => {
    try {
      await refreshExternalStorageUserPermissionsMutation({
        variables: { id }
      });
    } catch (error) {
      logger.errorMessage(`Unable to refresh external storage user permissions`);
      logger.error(error);
    }
  };

  return (
    <RequestFeedbackHandler
      requests={[createExternalStorageUserRequest, deleteExternalStorageUserRequest]}>
      <DracoonUserSection
        company={company}
        createExternalStorageUser={createExternalStorageUser}
        deleteExternalStorageUser={deleteExternalStorageUser}
        refreshExternalStorageUserPermissions={refreshExternalStorageUserPermissions}
        refetchCompany={refetchCompany}
      />
    </RequestFeedbackHandler>
  );
};

import { FiscalYearDataCompleteness } from "client/components/FiscalYearDataUploadConfirmation/FiscalYearDataUploadConfirmation";
import { GetFiscalYearsByCompanyId_getFiscalYearsByCompanyId } from "lib/api/queries/graphql/GetFiscalYearsByCompanyId";
import {
  parseFiscalYearDate,
  toFiscalYearDataCompleteness
} from "../toFiscalYearDataCompleteness/toFiscalYearDataCompleteness";
import { newDate } from "../../../common/dateProvider";

const getFiscalYearStartingYear = (fiscalYearStartingDate: string) => {
  const today = newDate();
  let year = today.getUTCFullYear();

  const fiscalYearStartingDateDate = parseFiscalYearDate(fiscalYearStartingDate, `${year}`);
  if (today < fiscalYearStartingDateDate) {
    year--;
  }

  return year;
};

export const toFiscalYearDataCompletenessList = (
  fiscalYearStartingDate: string | null | undefined,
  records: GetFiscalYearsByCompanyId_getFiscalYearsByCompanyId[]
): FiscalYearDataCompleteness[] => {
  if (!fiscalYearStartingDate) {
    return [];
  }

  const currentFiscalYearRecord: GetFiscalYearsByCompanyId_getFiscalYearsByCompanyId = {
    __typename: "FiscalYear",
    id: "current-fiscal-year",
    company: {
      __typename: "Company",
      id: "current-company",
      fiscalYear: fiscalYearStartingDate
    },
    dataUploadCompleted: false,
    year: `${getFiscalYearStartingYear(fiscalYearStartingDate)}`
  };

  return [currentFiscalYearRecord, ...records].map(toFiscalYearDataCompleteness);
};

import { GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients } from "../../taxAdvisor/graphql/GetMyTaxAdvisorCompanyWithClients";
import { FeatureFlagService } from "../../lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "../../global-query-types";
import { newDate } from "../dateProvider";

export function should3PTAMigrateWepaStandalone(
  company: GetMyTaxAdvisorCompanyWithClients_getMyTaxAdvisorCompanyWithClients
) {
  const migrate3PTAFeatureToggle = FeatureFlagService.instance.isEnabled(
    FeatureFlags.GRO982Migrate3PTAToLowtaxSteuerberatungsGmbH
  );

  const migrationRequiredSince = company.migrationRequiredFor3PTASince;
  const migrationPerformedAt = company.migrationRequiredFor3PTARealizedAt;

  return (
    migrate3PTAFeatureToggle &&
    migrationRequiredSince &&
    !migrationPerformedAt &&
    new Date(migrationRequiredSince).getTime() < newDate().getTime()
  );
}

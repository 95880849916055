import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Container, Form } from "react-bootstrap";
import { Formik } from "formik";
import { RealEstateFormSchema } from "../../lib/validation/RealEstateFormSchema";
import { UpdateRealEstateMutationVariables } from "./graphql/UpdateRealEstateMutation";
import { RealEstateQueryById_realEstatesById } from "./graphql/RealEstateQueryById";
import { noPhotoLocation } from "./NewRealEstateForm";
import { RealEstateOwnershipQueryByRealEstateId } from "./graphql/RealEstateOwnershipQueryByRealEstateId";
import { newDateWithoutLocalOffset } from "../../common/dateProvider";
import { RealEstateEditFormField } from "./RealEstateEditFormField";

interface newRealEstateProps extends WithTranslation {
  cancelForm: () => void | Promise<void>;
  submitForm: (
    finalForm: UpdateRealEstateMutationVariables,
    assetOwnerSince,
    assetOwnershipShare
  ) => void | Promise<void>;
  RealEstateDetailData: RealEstateQueryById_realEstatesById;
  OwnershipDetails: RealEstateOwnershipQueryByRealEstateId;
  loading: boolean;
}

export const RealEstateEditForm: React.FC<newRealEstateProps> = (props) => {
  const { t, submitForm, RealEstateDetailData, OwnershipDetails } = props;

  const ownershipInfo =
    OwnershipDetails && OwnershipDetails!.realEstateOwnership?.filter((rec) => rec.id)[0];

  const onSubmit = async (values) => {
    values["realEstateId"] = RealEstateDetailData.id;
    const assetOwnerSince = ownershipInfo?.startsAt ?? newDateWithoutLocalOffset();
    const assetOwnershipShare = values.percentageOwnership;

    await submitForm(values, assetOwnerSince, assetOwnershipShare);
  };

  return (
    <>
      <Formik
        initialValues={{
          realEstateAssetType: RealEstateDetailData.realEstateAssetType,
          label: RealEstateDetailData.label,
          propertyValue: RealEstateDetailData.propertyValue,
          percentageOwnership: ownershipInfo?.share,
          ownerId: ownershipInfo?.membership?.company?.id ?? "",
          shortDescription: RealEstateDetailData.shortDescription,
          image: RealEstateDetailData.image === noPhotoLocation ? "" : RealEstateDetailData.image,
          street: RealEstateDetailData.realEstateAddress!.street,
          postalCode: RealEstateDetailData.realEstateAddress!.postalCode,
          city: RealEstateDetailData.realEstateAddress!.city,
          latitude: RealEstateDetailData.latitude,
          longitude: RealEstateDetailData.longitude
        }}
        validationSchema={RealEstateFormSchema()}
        validateOnChange={true}
        onSubmit={onSubmit}>
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Container>
              <RealEstateEditFormField t={t} formik={formik} />

              <Button className="button float-sm-right mr-2" type="submit" data-testid="submit">
                {t("generic:save")}
              </Button>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withTranslation(["realEstate", "generic"])(RealEstateEditForm);

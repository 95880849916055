import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { formatDate } from "common/formatters";
import { Badge, Col, Row } from "react-bootstrap";
import { AlertMessage } from "../../../sharedComponents/AlertMessage/AlertMessage";
import { LinkVariant, TextLink } from "../../../sharedComponents/TextLink/TextLink";
import { BrokerageAccount as BrokerageAccountType } from "../../../lib/types/types";
import { RideCardBodySeparator } from "../../../sharedComponents/RideCardBodySeparator/RideCardBodySeparator";
import { BrokerBankEnum } from "../../../global-query-types";
import { rideBrokerT } from "../../../common/i18n/withTranslationReady";

interface BrokerageAccountListProps extends WithTranslation {
  list: BrokerageAccountType[];
  onEdit: (id: string) => void;
  onAdd: () => void;
}

interface BrokerageErrorsProps {
  errorCodes: any | null;
  accountId: string;
  t;
}

const BrokerageErrors = ({ errorCodes, accountId, t }: BrokerageErrorsProps) => {
  return (
    <>
      {errorCodes &&
        errorCodes.map((errorCode) => {
          const dataTestId = `brokerage-account-alert-${accountId}`;
          const kBChapter = t(`brokerage-account-errors:kB-chapter.${errorCode}`);
          return (
            <AlertMessage data-testid={dataTestId} key={errorCode}>
              <>
                <div className="font-weight-bold">
                  {t(`brokerage-account-errors:type.${errorCode}`)}
                </div>
                <div>{t(`brokerage-account-errors:description.${errorCode}`)}</div>
                <div>
                  <TextLink
                    variant={LinkVariant.secondary}
                    data-testid={`${dataTestId}-kblink`}
                    target="_blank"
                    rel="noopener noreferrer"
                    to={t("brokerage-account-errors:defaultKBLink")}>
                    {t(`brokerage-account-errors:default-text`)}
                  </TextLink>
                </div>
                {kBChapter && errorCode !== "IB_TOKEN_PROCESSING" && (
                  <div data-testid={"kb-chapter"}>
                    {t("brokerage-account-errors:kB-chapter-text")}
                    {kBChapter}
                  </div>
                )}
              </>
            </AlertMessage>
          );
        })}
    </>
  );
};

const BrokerageAccountList = ({ list, onEdit, onAdd, t }: BrokerageAccountListProps) => {
  return (
    <RideSectionCard
      data-testid="brokerage-account-list"
      title={t("client:company.brokerage-account-list.title")}
      className="my-5">
      {list.map((account, index) => (
        <div key={index}>
          {index !== 0 ? <RideCardBodySeparator /> : null}
          <BrokerageAccount account={account} onEdit={onEdit} t={t} />
        </div>
      ))}

      {list.length === 0 && (
        <div
          data-testid="brokerage-account-empty-message"
          className="brokerage-account-list--empty">
          {t(`client:company.brokerage-account-list.empty-message`)}
        </div>
      )}

      <button
        data-testid="brokerage-account-add"
        className="brokerage-account-list--add"
        onClick={() => {
          onAdd();
        }}>
        {t(`client:company.brokerage-account-list.add-message`)}
      </button>
    </RideSectionCard>
  );
};

const ValidityBadge = ({
  validity,
  brokerBank,
  dataTestId,
  t
}: {
  validity: string | null;
  brokerBank: BrokerBankEnum;
  dataTestId: string;
  t;
}) => {
  let state: string;
  let badgeTranslationKey: string = "client:company.brokerage-account-list.status.";

  if (!validity || validity === "New" || validity === "Processing") {
    state = "warning";
    badgeTranslationKey += "inProgress";
  } else if (validity === "Valid") {
    state = "info";
    badgeTranslationKey += "valid";
  } else {
    state = "danger";
    badgeTranslationKey += "invalid";
  }

  let infoTranslationKey: string =
    "client:company.brokerage-account-list.activation-time-info.default";

  if (brokerBank === BrokerBankEnum.IB) {
    infoTranslationKey = "client:company.brokerage-account-list.activation-time-info.IB";
  }

  if (brokerBank === BrokerBankEnum.LTB) {
    state = "info";
    badgeTranslationKey = "client:company.brokerage-account-list.status.valid";
  }

  return (
    <div className="brokerage-account-list__state">
      <Badge
        data-testid={dataTestId}
        variant={state}
        className={`p-2 soft-badge soft-badge--${state}`}>
        {t(badgeTranslationKey)}
      </Badge>
      {state === "warning" && (
        <div
          className="brokerage-account-list__activation-info"
          data-testid="brokerage-account-activation-time-info">
          {t(infoTranslationKey)}
        </div>
      )}
    </div>
  );
};

const BrokerageAccount = ({
  account,
  onEdit,
  t
}: {
  account: BrokerageAccountType;
  onEdit: (id: string) => void;
  t: TFunction;
}) => {
  return (
    <div data-testid="brokerage-account-item" className="brokerage-account-list--item">
      <Col>
        <Row>
          <Col>
            <Row>
              <div data-testid="brokerage-account-item-type" className="w-100">
                {account.brokerName
                  ? t(
                      rideBrokerT(
                        `client:company.brokerage-account-list.brokerName.${account.brokerName}`
                      )
                    )
                  : t("client:brokerage-account.broker-bank")}
              </div>
              <div
                data-testid="brokerage-account-item-date"
                className="brokerage-account-list-tokenUpdatedAt">
                {t(`client:company.brokerage-account-list.tokenUpdatedAt`)}:{" "}
                {formatDate(account.tokenUpdatedAt)}
              </div>
            </Row>
          </Col>
          <Col xs={7} className="p-0">
            <Row noGutters>
              <Col className="d-flex justify-content-end align-items-start justify-content-md-between align-items-md-center flex-wrap">
                <ValidityBadge
                  dataTestId={`brokerage-account-badge-${account.id}`}
                  validity={account.validity}
                  brokerBank={account.brokerBank}
                  t={t}
                />
              </Col>
              {account.brokerBank !== BrokerBankEnum.LTB && (
                <Col md={3} xs={12} className="d-flex justify-content-end">
                  <button
                    data-testid="brokerage-account-edit"
                    className="brokerage-account-list--edit p-0"
                    onClick={() => onEdit(account.id)}>
                    {t(`client:company.brokerage-account-list.edit-message`)}
                  </button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 7, offset: 5 }} className="p-0 p-md-3">
            {account.brokerBank !== BrokerBankEnum.LTB && (
              <BrokerageErrors errorCodes={account.errorCodes} accountId={account.id} t={t} />
            )}
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default withTranslation(["broker-v2", "generic", "client", "brokerage-account-errors"])(
  BrokerageAccountList
);

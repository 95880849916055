import React from "react";
import { ErrorMessage, FormikProps } from "formik";
import { UserRole } from "../../../global-query-types";
import { ClientSelect } from "../../../client/components/ClientSelect/ClientSelect";

const UserRoles = [
  { value: UserRole.Client, label: UserRole.Client },
  { value: UserRole.TaxAdvisor, label: UserRole.TaxAdvisor },
  { value: UserRole.Nominee, label: UserRole.Nominee },
  { value: UserRole.ThirdPartyTaxAdvisor, label: UserRole.ThirdPartyTaxAdvisor }
];

interface UserRoleDropdownProps {
  testId?: string;
  label?: string;
  name: string;
  formik: FormikProps<any>;
}

export const UserRoleDropdown = ({ testId, label, name, formik }: UserRoleDropdownProps) => {
  const dataTestId = testId ? testId : name;
  return (
    <div data-testid={`${dataTestId}-container`}>
      {label && (
        <label
          data-testid={`${dataTestId}-select-label`}
          htmlFor={name}
          className="client-select__label d-block">
          {label}
        </label>
      )}
      <div data-testid={`${dataTestId}-select-container`}>
        <ClientSelect
          name={name}
          id={name}
          options={UserRoles}
          onChange={(option) => formik.handleChange(`role`)(option ?? "")}
          onBlur={formik.handleBlur}
          value={formik.values[name].value}
        />
      </div>
      {formik.errors[name] && (
        <ErrorMessage
          className="client-input__error mt-2"
          component="div"
          name={name}
          data-testid={`${testId}-error`}
        />
      )}
    </div>
  );
};

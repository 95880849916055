import React from "react";
import Entity from "./Entity";
import { useParams } from "react-router";
import { WithTranslation } from "react-i18next";
import { FeatureFlags, OrderOwnerType, WepaBookingStatusFlag } from "global-query-types";
import { GetWepaBookings } from "lib/api/queries/GetWepaBookings";
import { QueryCompanyById } from "lib/api/queries/GetCompanyById";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { GetStorageConfig } from "lib/api/queries/GetStorageConfig";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { PageMetaData } from "sharedComponents/PageMetaData/PageMetaData";
import { CompanyById_companyById } from "lib/api/queries/graphql/CompanyById";
import { useDeleteAdminService } from "components/common/deleteAdminService";
import { SET_WEPA_BOOKING_FLAG } from "lib/api/mutations/SET_WEPA_BOOKING_FLAG";
import RequestFeedbackHandler from "components/generic/RequestFeedbackHandler";
import { FileUploadService } from "lib/services/FileUploadService/FileUploadService";
import { CompanyTabbedNavigationValue } from "../CompanyTabbedNavigation/CompanyTabbedNavigation";
import {
  setWepaBookingFlag,
  setWepaBookingFlagVariables
} from "lib/api/mutations/graphql/setWepaBookingFlag";
import {
  CreateAdminServiceAddOn,
  CreateAdminServiceAddOnVariables
} from "../../../components/client/company/graphql/CreateAdminServiceAddOn";
import { CREATE_ADMIN_SERVICE_ADD_ON } from "../../../components/client/company/Mutations";
import { ExecutionResult } from "graphql";
import logger from "../../../common/Logger";
import {
  UpsertBankAccount,
  UpsertBankAccountVariables
} from "../../../lib/api/mutations/graphql/UpsertBankAccount";
import { UPSERT_BANK_ACCOUNT } from "../../../lib/api/mutations/UPSERT_BANK_ACCOUNT";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { QueryGetCompaniesWithASContractStatus } from "../../../lib/api/queries/QueryGetCompaniesWithASContractStatus";
import { GetFormerClientAssignmentsQuery } from "../../../lib/api/queries/GetFormerClientAssignmentsQuery";
import { GetFormerClientAssignments_getFormerClientAssignments_items } from "../../../lib/api/queries/graphql/GetFormerClientAssignments";
import { UpsertOrder, UpsertOrderVariables } from "../../../lib/api/mutations/graphql/UpsertOrder";
import { UPSERT_ORDER } from "../../../lib/api/mutations/UPSERT_ORDER";
import { GetMe } from "../../../lib/api/queries/MeQuery";
import { RideSteuerberaterMigrationPopupWrapper } from "../AdminServicePTAMigrationToRideSteuerberatung/PtaMigrationFlowToRideSteuerberatung/PtaMigrationToRideSteuerberatungWrapper";

const EntityContainer = ({ t }: WithTranslation) => {
  const { id, mode } = useParams<{ id: string; mode: CompanyTabbedNavigationValue }>();

  const companyRequest = QueryCompanyById(id);
  const wepaBookingsRequest = GetWepaBookings({ companyId: id });
  const [setWepaBookingFlagCall, setWepaBookingFlagRequest] = useMutation<
    setWepaBookingFlag,
    setWepaBookingFlagVariables
  >(SET_WEPA_BOOKING_FLAG);

  const [upsertBankAccount, upsertBankAccountRequest] = useMutation<
    UpsertBankAccount,
    UpsertBankAccountVariables
  >(UPSERT_BANK_ACCOUNT);

  const [upsertOrder, upsertOrderRequest] = useMutation<UpsertOrder, UpsertOrderVariables>(
    UPSERT_ORDER
  );

  const loggedUser = GetMe();
  const loggedInPersonId = loggedUser.data?.me?.person?.id;

  const upsertOrderHandler = async (data: UpsertOrderVariables) => {
    const order = await upsertOrder({
      variables: {
        ...data,
        ownerId: loggedInPersonId,
        ownerType: OrderOwnerType.Person
      }
    });
    await refetchCompany();
    return order.data?.upsertOrder;
  };

  const companiesWithASContractStatusRequest = QueryGetCompaniesWithASContractStatus();

  const onConfirmBooking = async (bookingId: number) => {
    await setWepaBookingFlagCall({
      variables: { bookingId, companyId: id, flag: WepaBookingStatusFlag.customer_final }
    });
    await wepaBookingsRequest.refetch();
  };

  const client = useApolloClient();

  const refetchDocuments = async () => {
    // NOTE: any other way would require a lot of extra effort, and this looks quite good for now
    await client.reFetchObservableQueries();
  };

  const { archiveAdminService, setArchiveStatusRequest } = useDeleteAdminService();

  const cancelAdminService = async (id: string) => {
    await archiveAdminService(id);
    await refetchDocuments();
  };

  const company = companyRequest.data?.companyById;
  const wepaBookings = wepaBookingsRequest.data?.getWepaBookings?.bookings ?? [];

  const refetchCompany = async () => {
    await companyRequest.refetch();
  };

  const formerClientAssignmentsRequest = GetFormerClientAssignmentsQuery({
    clientId: id
  });
  const formerClientAssignments: GetFormerClientAssignments_getFormerClientAssignments_items[] =
    formerClientAssignmentsRequest.data?.getFormerClientAssignments?.items ?? [];

  const storageConfigRequest = GetStorageConfig();
  const storageConfig = storageConfigRequest.data?.getStorageConfig?.config;

  FileUploadService.getInstance().setRefetch(async () => {
    await refetchDocuments();
  });

  const [createAdminServiceAddOnMutation] = useMutation<
    CreateAdminServiceAddOn,
    CreateAdminServiceAddOnVariables
  >(CREATE_ADMIN_SERVICE_ADD_ON);

  const createAdminServiceAddOn = async (): Promise<ExecutionResult<CreateAdminServiceAddOn>> => {
    let result;
    try {
      result = await createAdminServiceAddOnMutation({ variables: { companyId: id } });
    } catch (err) {
      logger.error(err);
    }

    return result;
  };

  const upsertBankAccountCall = async (variables) => {
    await upsertBankAccount({
      variables
    });

    await refetchCompany();
  };

  const getPageTabTitle = (
    company: CompanyById_companyById,
    mode: CompanyTabbedNavigationValue
  ): string => {
    const titlesMap = [
      {
        key: CompanyTabbedNavigationValue.PRIMARY_DATA,
        value: t("generic:company.general-info.page-title", { entityName: company.name })
      },
      {
        key: CompanyTabbedNavigationValue.INVEST,
        value: t("generic:company.general-info.page-title", { entityName: company.name })
      },
      {
        key: CompanyTabbedNavigationValue.ADMIN_SERVICE,
        value: t("generic:company.general-info.page-title", { entityName: company.name })
      },
      {
        key: CompanyTabbedNavigationValue.WEPA,
        value: t("generic:company.general-info.page-title", { entityName: company.name })
      }
    ];

    return titlesMap[mode]?.value ?? "";
  };

  const showBackLink = !FeatureFlagService.instance.isEnabled(
    FeatureFlags.ReplaceCompanyListByCompanyDropdown
  );

  return (
    <RequestFeedbackHandler
      requests={[
        companyRequest,
        formerClientAssignmentsRequest,
        storageConfigRequest,
        setWepaBookingFlagRequest,
        wepaBookingsRequest,
        setArchiveStatusRequest,
        upsertBankAccountRequest,
        companiesWithASContractStatusRequest,
        upsertOrderRequest
      ]}
      noLoadingAnimation={true}>
      {!!company && (
        <>
          <PageMetaData title={getPageTabTitle(company, mode)} />
          <div className="important-banners-section">
            <RideSteuerberaterMigrationPopupWrapper company={company} />
          </div>
          <Entity
            cancelAdminService={cancelAdminService}
            companiesWithASContractStatus={companiesWithASContractStatusRequest.data?.companies!}
            company={company}
            createAdminServiceAddOn={createAdminServiceAddOn}
            formerClientAssignments={formerClientAssignments}
            goBackLink={showBackLink ? "/client/entities" : undefined}
            onConfirmBooking={onConfirmBooking}
            refetchCompany={refetchCompany}
            storageConfig={storageConfig}
            upsertBankAccount={upsertBankAccountCall}
            upsertOrder={upsertOrderHandler}
            viewMode={mode}
            wepaBookings={wepaBookings}
          />
        </>
      )}
    </RequestFeedbackHandler>
  );
};

export default withTranslationReady(["generic"])(EntityContainer);

import { createHttpLink } from "apollo-link-http";
import logger from "../Logger";

export const getEndpoint = () => {
  const endpoint = process.env.REACT_APP_API_ENDPOINT as string;
  if (endpoint == null || endpoint.length === 0) {
    throw new Error("Endpoint not configured");
  }

  logger.debug(`GraphQL endpoint is '${endpoint}'`);
  return endpoint;
};

export const httpLink = createHttpLink({
  uri: getEndpoint
});

export const getPaymentsEndpoint = () => {
  const reactAppApiEndpoint = process.env.REACT_APP_API_ENDPOINT as string;
  if (reactAppApiEndpoint == null || reactAppApiEndpoint.length === 0) {
    throw new Error("Endpoint not configured");
  }

  const endpoint = `${reactAppApiEndpoint}/payments-graphql`;

  logger.debug(`GraphQL endpoint is '${endpoint}'`);
  return endpoint;
};

export const paymentsHttpLink = createHttpLink({
  uri: getPaymentsEndpoint
});

export const getSecuritiesEndpoint = () => {
  const reactAppApiEndpoint = process.env.REACT_APP_API_ENDPOINT as string;
  if (reactAppApiEndpoint == null || reactAppApiEndpoint.length === 0) {
    throw new Error("Endpoint not configured");
  }

  const endpoint = `${reactAppApiEndpoint}/securities-graphql`;

  logger.debug(`GraphQL endpoint is '${endpoint}'`);
  return endpoint;
};

export const securitiesHttpLink = createHttpLink({
  uri: getSecuritiesEndpoint
});

import gql from "graphql-tag";

export const NOMINEE_UPDATE_MEMBERSHIP = gql`
  mutation NomineeUpdateMembership(
    $companyId: ID!
    $memberPersonId: ID
    $memberCompanyId: ID
    $isManagingDirector: Boolean
    $isSilentPartner: Boolean
    $share: Float!
    $votes: Float!
  ) {
    nomineeUpdateMembership(
      companyId: $companyId
      memberPersonId: $memberPersonId
      memberCompanyId: $memberCompanyId
      isManagingDirector: $isManagingDirector
      isSilentPartner: $isSilentPartner
      share: $share
      votes: $votes
    ) {
      feedback
    }
  }
`;

export const NOMINEE_CREATE_COMPANY_STORAGE_STRUCTURE = gql`
  mutation NomineeCreateCompanyStorageStructure($id: ID!) {
    nomineeCreateCompanyStorageStructure(id: $id) {
      feedback
    }
  }
`;

export const NOMINEE_CREATE_ADMIN_SERVICE_STORAGE_STRUCTURE = gql`
  mutation NomineeCreateAdminServiceStorageStructure($id: ID!) {
    nomineeCreateAdminServiceStorageStructure(id: $id) {
      feedback
    }
  }
`;

export const NOMINEE_UPDATE_COMPANY_STORAGE_STRUCTURE = gql`
  mutation NomineeUpdateCompanyStorageStructure($id: ID!) {
    nomineeUpdateCompanyStorageStructure(id: $id) {
      feedback
    }
  }
`;

export const NOMINEE_UPDATE_TAX_SERVICE = gql`
  mutation NomineeUpdateTaxService(
    $taxServiceId: String!
    $advisorStatus: TaxAdvisorStatusEnum
    $activatedAt: String
  ) {
    updateTaxService(
      taxServiceId: $taxServiceId
      advisorStatus: $advisorStatus
      activatedAt: $activatedAt
    ) {
      feedback
    }
  }
`;

export const CREATE_ADMIN_SERVICE_FLOW = gql`
  mutation CreateAdminServiceFlow($companyId: ID!) {
    createAdminServiceFlow(companyId: $companyId) {
      feedback
    }
  }
`;

export const ASSIGN_TAX_SERVICE_TO_COMPANY = gql`
  mutation AssignTaxServiceToCompany($taxServiceCompanyId: ID, $clientCompanyId: ID!) {
    assignTaxServiceToCompany(
      taxServiceCompanyId: $taxServiceCompanyId
      clientCompanyId: $clientCompanyId
    ) {
      feedback
    }
  }
`;

export const NOMINEE_CREATE_EXTERNAL_STORAGE_USER = gql`
  mutation NomineeCreateExternalStorageUser(
    $email: String!
    $password: String!
    $taxAdvisorCompanyId: ID!
    $personId: ID!
  ) {
    nomineeCreateExternalStorageUser(
      email: $email
      password: $password
      taxAdvisorCompanyId: $taxAdvisorCompanyId
      personId: $personId
    ) {
      feedback
    }
  }
`;

export const ASSIGN_TAX_ADVISOR_STORAGE_PERMISSIONS = gql`
  mutation AssignTaxAdvisorStoragePermissions($clientCompanyId: ID!) {
    assignTaxAdvisorStoragePermissions(clientCompanyId: $clientCompanyId) {
      feedback
    }
  }
`;

export const SET_PROCESS_STATES = gql`
  mutation setProcessStates($id: ID!, $processStates: Json!) {
    setProcessStates(id: $id, processStates: $processStates) {
      feedback
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $name: String!
    $legalForm: LegalForm!
    $capitalContribution: Float!
    $street: String!
    $city: String!
    $postalCode: String!
    $type: CompanyType
    $tradeTaxFree: Boolean
    $registerCourt: String
    $registration: String!
    $taxNumber: String
    $vatNumber: String
  ) {
    createCompany(
      name: $name
      legalForm: $legalForm
      capitalContribution: $capitalContribution
      street: $street
      city: $city
      postalCode: $postalCode
      type: $type
      tradeTaxFree: $tradeTaxFree
      registerCourt: $registerCourt
      registration: $registration
      taxNumber: $taxNumber
      vatNumber: $vatNumber
    ) {
      id
      name
      legalForm
      capitalContribution
      businessAddress {
        street
        city
        postalCode
      }
      tradeTaxFree
      registerCourt
      registration
      taxNumber
      vatNumber
    }
  }
`;

export const SET_NOTARIES = gql`
  mutation setNotaries(
    $companyCreationId: ID!
    $firstParty: String!
    $firstPartyAppointment: String
    $thirdParty: String
    $thirdPartyAppointment: String
  ) {
    setNotaries(
      companyCreationId: $companyCreationId
      firstParty: $firstParty
      firstPartyAppointment: $firstPartyAppointment
      thirdParty: $thirdParty
      thirdPartyAppointment: $thirdPartyAppointment
    ) {
      feedback
    }
  }
`;

export const NOMINEE_ADD_EMPTY_COMPANY_FOR_PERSON = gql`
  mutation NomineeAddEmptyCompanyForPerson($personId: ID!) {
    nomineeAddEmptyCompanyForPerson(personId: $personId) {
      feedback
    }
  }
`;
